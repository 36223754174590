import {
  IBudget,
  IBudgetItemOutsourcedService,
  IBudgetLaborCost,
  IBudgetLaborCostTypeEnum,
  IBudgetPart,
  IBudgetWorkshopAddress,
} from 'commom/types/Budget.types';
import { IInspection } from 'pages/SchedulerList/components/Inspection';
import DateService from 'services/DateService/dateService';
import { formatDecimalValue } from 'utils/formatDecimalValue';
import { getVehicleKilometers } from 'utils/formatDistance';
import { priceFormatter } from 'utils/formatPrice';
import { capitalizeFirstLetter } from 'utils/formatString';
import arrowDownIcon from '../../assets/svg/arrowDown.svg';
import arrowUpIcon from '../../assets/svg/arrowUp.svg';
import budgetValuesWhiteIcon from '../../assets/svg/budget-template/icon-budget-value-white.svg';
import carManualPartsIcon from '../../assets/svg/budget-template/icon-car-manual-parts.svg';
import carPartsIcon from '../../assets/svg/budget-template/icon-car-parts.svg';
import conclusionWhiteIcon from '../../assets/svg/budget-template/icon-conclusion-white.svg';
import customerIcon from '../../assets/svg/budget-template/icon-customer.svg';
import servicesHeaderIcon from '../../assets/svg/budget-template/icon-services.svg';
import vehicleIcon from '../../assets/svg/budget-template/icon-vehicle.svg';
import workshopIcon from '../../assets/svg/budget-template/icon-workshop.svg';
import clockIcon from '../../assets/svg/clock.svg';
import { IReportService } from './reportService.types';

const formatAddress = ({ cep, city, fullAddress, neighborhood, uf }: IBudgetWorkshopAddress) => {
  return `${fullAddress}, ${neighborhood} ${city ? `- ${city}` : ''} ${uf ? `- ${uf}` : ''} ${cep ? `- ${cep}` : ''}`;
};

const showHourService = (hourService: number) => {
  return hourService > 0 ? formatDecimalValue(hourService) : '-';
};

const formatPartsQuantity = (quantity: number): string => quantity.toString().padStart(2, '0');

const getAllPartsQuantity = (parts: IBudgetPart[]) => {
  return parts.reduce((total, part) => total + part.quantity, 0);
};

const getCostPriceByType = (type: IBudgetLaborCostTypeEnum | string, budgetCosts: IBudgetLaborCost[]) => {
  const filteredCost = budgetCosts.find((cost) => cost.type === type);

  return filteredCost?.price || undefined;
};

const formatPercent = (percent: number | undefined) => {
  return percent ? `${percent.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%` : '-';
};

const formatPriceBRL = (price: number | undefined) => {
  return price ? price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-';
};

const formatDecimal = (price: number | undefined) => {
  return price ? price.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-';
};

const renderPartTempServiceTypeIcon = (serviceType: string) => {
  const partTempServiceTypes: { [key: string]: string } = {
    repair: '<div class="service-badge badge-small bg-red">R</div>',
    painting: '<div class="service-badge badge-small bg-purple">P</div>',
    removeAndInstall: '<div class="service-badge badge-small bg-orange">R&I</div>',
  };

  return serviceType ? partTempServiceTypes[serviceType] : '';
};

const renderPartTempService = (part: IBudgetPart) => {
  return Object.entries(part.serviceHours)
    .map(([key, value]) => {
      return value !== 0
        ? `
      <div class="service-icons">
        ${renderPartTempServiceTypeIcon(key)}
        <div class="item-subtitle justify-start gap-2 m-top-1">
          <img src="${clockIcon}" alt="clock" width="10" />
          <div class="semi-bold">${showHourService(value)}</div>
        </div>
      </div>
    `
        : '';
    })
    .join('');
};

const showPartsAndServicesIfExist = (budget: IBudget, inspection: IInspection) => {
  const paintingType = inspection.product?.vehicle?.paintingType?.toString() || IBudgetLaborCostTypeEnum.PAINTING;
  const laborCost = getCostPriceByType(IBudgetLaborCostTypeEnum.LABOR, budget.labor.cost);
  const paintingCost = getCostPriceByType(paintingType, budget.labor.cost);
  const repairCost = getCostPriceByType(IBudgetLaborCostTypeEnum.REPAIR, budget.labor.cost);
  const discountCost = budget.labor.discount;
  const increaseCost = budget.labor.increase;

  return `
  ${showPartsIfExist(budget.items.parts)}


  <div class="flex gap-10 divider-top padding-v-10 padding-h-20 space-between">
    <div class="flex gap-10">
      <img src="${servicesHeaderIcon}" alt="clock" width="18" />
      <h1 class="bold">Serviços</h1>
    </div>

    <div class="flex gap-10">
      <h1 class="normal">Qtd. Total de serviços</h1>
      <div class="semi-bold">${formatPartsQuantity(budget.items.outsourcedServices.length)}</div>
    </div>
  </div>

  ${showOutsourcedServicesIfExist(budget.items.outsourcedServices)}
  <div class="page-break-inside">
    <div class="subtitle">
      <div class="flex justify-center gap-30 divider-top divider-bottom padding-v-10">
        <div class="flex gap-10 align-center">
          <div class="service-badge badge-medium bg-orange">R&I</div>
          <div class="description">Remoção & Instalação</div>
        </div>

        <div class="flex gap-10 align-center">
          <div class="service-badge badge-medium bg-red">R</div>
          <div class="description">Reparação</div>
        </div>

        <div class="flex gap-10 align-center">
          <div class="service-badge badge-medium bg-purple">P</div>
          <div class="description">Pintura</div>
        </div>

        <div class="flex gap-10 align-center">
          <div class="value-badge badge-medium">
            <img src="${arrowUpIcon}" alt="arrow up" width="10" />
          </div>
          <div class="description">Acima do padrão</div>
        </div>

        <div class="flex gap-10 align-center">
          <div class="value-badge badge-medium">
            <img src="${arrowUpIcon}" alt="arrow down" width="10" />
          </div>
          <div class="description">Abaixo do padrão</div>
        </div>
      </div>

      <div class="part-type">
        <div class="flex column gap-10">
          <div class="label-origin m-right-auto">Original</div>
          <div class="ft-size-10">
            Peça de reposição de mesma origem do fabricante, usada para reparo ou manutenção, mas não na linha de
            montagem.
          </div>
        </div>

        <div class="flex column gap-10">
          <div class="label-genuine m-right-auto">Genuína</div>
          <div class="ft-size-10">Peça original: idêntica à usada na linha de montagem de veículos.</div>
        </div>

        <div class="flex column gap-10">
          <div class="label-green m-right-auto">Verde</div>
          <div class="ft-size-10">Peça reciclada ou reaproveitada de componentes automotivos.</div>
        </div>

        <div class="flex column gap-10">
          <div class="label-other-sources m-right-auto">Outras Fontes</div>
          <div class="ft-size-10">Peça de fabricante independente da montadora do veículo.</div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-break-inside">

    <div class="flex gap-10 divider-bottom divider-top padding-v-10 padding-h-20 section">
      <img src="${budgetValuesWhiteIcon}" alt="clock" width="18" />
      <div class="bold ft-size-14 color-white">Valores do Orçamento</div>
    </div>

    <div class="budget-values-services flex space-around">
      <div class="budget-container border-container flex column space-between">
        <div class="service-prices-header gap-20">
          <div class="row">
            <div class="label">Reparação</div>
            <div class="value">${repairCost ? `R$ ${repairCost}/h` : '-'}</div>
          </div>

          <div class="row gap-10">
            <div class="label">Remoção & instalação</div>
            <div class="value">${laborCost ? `R$ ${laborCost}/h` : '-'}</div>
          </div>

          <div class="row">
            <div class="label">Pintura ${inspection.product?.vehicle?.paintingDescription}</div>
            <div class="value">${paintingCost ? `R$ ${paintingCost}/h` : '-'}</div>
          </div>

          <div class="row">
            <div class="label break-spaces">Desconto</div>
            <div class="value">${formatPercent(discountCost)}</div>
          </div>

          <div class="row">
            <div class="label break-spaces">Majoração</div>
            <div class="value">${formatPercent(increaseCost)}</div>
          </div>
        </div>

        <div class="service-prices-body flex column gap-20">
          <div class="total-prices flex gap-10">
            <div class="flex column gap-10">
              <div class="label">Total Mão de obra</div>
              <div class="value color-blue">${formatPriceBRL(budget.summaryCost.services.total)}</div>
            </div>

            <div class="flex column gap-10">
              <div class="label">Horas Totais</div>
              <div class="value">
                <img src="${clockIcon}" alt="clock" width="10" />
                ${formatDecimal(budget.summaryCost.hours.total)}
              </div>
            </div>
          </div>

          <div class="flex column gap-5">
            <div class="row flex space-between">
              <div class="flex gap-10 ft-size-12 align-center">
                <div class="orange-dot"></div>
                <div>Remoção & instalação (${formatPercent(budget.summaryCost.percentages.replacement)})</div>
              </div>

              <div class="flex gap-10 ft-size-12 align-center">
                <div class="semi-bold">${formatPriceBRL(budget.summaryCost.services.replacement)}</div>
                <div class="flex gap-3">
                  <img src="${clockIcon}" alt="clock" width="8" />${formatDecimal(budget.summaryCost.hours.replacement)}
                </div>
              </div>
            </div>

            <div class="row flex space-between">
              <div class="flex gap-10 ft-size-12 align-center">
                <div class="red-dot"></div>
                <div>Reparação (${formatPercent(budget.summaryCost.percentages.repair)})</div>
              </div>

              <div class="flex gap-10 ft-size-12 align-center">
                <div class="semi-bold">${formatPriceBRL(budget.summaryCost.services.repair)}</div>
                <div class="flex gap-3">
                  <img src="${clockIcon}" alt="clock" width="8" />${formatDecimal(budget.summaryCost.hours.repair)}
                </div>
              </div>
            </div>

            <div class="row flex space-between">
              <div class="flex gap-10 ft-size-12 align-center">
                <div class="red-dot"></div>
                <div>Pintura (${formatPercent(budget.summaryCost.percentages.painting)})</div>
              </div>

              <div class="flex gap-10 ft-size-12 align-center">
                <div class="semi-bold">${formatPriceBRL(budget.summaryCost.services.painting)}</div>
                <div class="flex gap-3">
                  <img src="${clockIcon}" alt="clock" width="8" />${formatDecimal(budget.summaryCost.hours.painting)}
                </div>
              </div>
            </div>

            <div class="row flex space-between">
              <div class="flex gap-10 ft-size-12 align-center">
                <div class="red-dot"></div>
                <div>Outros serviços (${formatPercent(budget.summaryCost.percentages.outsourcedServices)})</div>
              </div>

              <div class="flex gap-10 ft-size-12 align-center">
                <div class="semi-bold">${formatPriceBRL(budget.summaryCost.services.outsourcedServices)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="budget-container border-container flex column">
        <div class="section-top flex gap-5">
          <div class="flex column gap-5">
            <div class="section-label">Total bruto peças</div>
            <div class="section-value">${formatPriceBRL(budget.summaryCost.parts.gross)}</div>
          </div>

          <div>-</div>

          <div class="flex column gap-5">
            <div class="section-label">Desconto</div>
            <div class="section-value green">${priceFormatter.format(budget.summaryCost.parts.discount)}</div>
          </div>

          <div>+</div>

          <div class="flex column gap-5">
            <div class="section-label">Majoração</div>
            <div class="section-value red">${priceFormatter.format(budget.summaryCost.parts.increase)}</div>
          </div>

          <div>=</div>

          <div class="flex column gap-5">
            <div class="section-label">Líquido Peças</div>
            <div class="section-value">${formatPriceBRL(budget.summaryCost.parts.net)}</div>
          </div>
        </div>

        <div class="section-middle flex gap-20">
          <div class="flex column gap-5">
            <div class="section-label">Líquido Peças</div>
            <div class="section-value">${formatPriceBRL(budget.summaryCost.parts.net)}</div>
          </div>

          <div>+</div>

          <div class="flex column gap-5">
            <div class="section-label">Total mão de Obra</div>
            <div class="section-value">${formatPriceBRL(budget.summaryCost.services.total)}</div>
          </div>

          <div>=</div>

          <div class="flex column gap-5">
            <div class="section-label">Total Geral</div>
            <div class="section-value">${formatPriceBRL(budget.summaryCost.gross)}</div>
          </div>
        </div>

        <div class="section-bottom flex gap-5">
          <div class="flex column gap-5">
            <div class="section-label">Total Geral</div>
            <div class="section-value ft-size-16">${formatPriceBRL(budget.summaryCost.gross)}</div>
          </div>

          <div>-</div>

          <div class="flex column gap-5">
            <div class="section-label">Franquia</div>
            <div class="section-value green ft-size-16">${formatPriceBRL(budget.deductible)}</div>
          </div>

          <div>=</div>

          <div class="flex column gap-5">
            <div class="section-label">Líquido Geral</div>
            <div class="section-value blue ft-size-16">${formatPriceBRL(budget.summaryCost.net)}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
};

const showOutsourcedServicesIfExist = (outsourcedServices: IBudgetItemOutsourcedService[]) => {
  return outsourcedServices?.length > 0
    ? renderBudgetOutsourcedServices(outsourcedServices)
    : '<div id="empty-parts">Sem serviços adicionados</div>';
};

const showPartsIfExist = (parts: IBudgetPart[]) => {
  const manualParts = parts.filter((part) => part.isManualEntry);
  const nonManualParts = parts.filter((part) => !part.isManualEntry);

  return parts?.length > 0
    ? `
    ${showParts(nonManualParts, 'Peças', carPartsIcon)}
    ${showParts(manualParts, 'Peças incluídas manualmente', carManualPartsIcon)}
    `
    : `
    <div class="flex gap-10 divider-top padding-v-10 padding-h-20 space-between">
      <div class="flex gap-10">
        <img src="${carPartsIcon}" alt="clock" width="18" />
        <h1 class="bold">Peças</h1>
      </div>

      <div class="flex gap-10">
        <h1 class="normal">Qtd. Total de Peças</h1>
        <div class="semi-bold">00</div>
      </div>
    </div>
    <div id="empty-parts">Sem peças adicionadas</div>`;
};

const showParts = (parts: IBudgetPart[], title: string, icon: string) => {
  return `
    <div class="flex gap-10 divider-top padding-v-10 padding-h-20 space-between page-break-inside">
      <div class="flex gap-10">
        <img src="${icon}" alt="clock" width="18" />
        <h1 class="bold">${title}</h1>
      </div>

      <div class="flex gap-10">
        <h1 class="normal">Qtd. Total de Peças</h1>
        <div class="semi-bold">${formatPartsQuantity(getAllPartsQuantity(parts))}</div>
      </div>
    </div>
      ${parts?.length > 0 ? renderBudgetParts(parts) : `<div id="empty-parts">Sem ${title.toLocaleLowerCase()} adicionadas</div>`}
    `;
};

const showArrowIndicator = (original: number, current: number): string => {
  if (current === 0 || current === original) {
    return '';
  }

  const icon = current > original ? arrowUpIcon : arrowDownIcon;
  return `<img src="${icon}" alt="arrow ${current > original ? 'up' : 'down'}" width="10" />`;
};

const renderBudgetParts = (parts: IBudgetPart[]) => {
  return `
  <div class="parts-table">
    ${parts
      .map(
        (part) => `
        <div class="parts-table-row flex space-between">
          <div class="parts-table-name">
            <div class="flex column gap-5">
              ${renderPartTempService(part)}
            </div>
            <div class="flex">
              <div class="label-container w-65">
                <strong>${capitalizeFirstLetter(part.name)}</strong>
                <span>${part.sku}</span>
              </div>
            </div>

            <div class="label-part">
              <div class="item-title color-title">Tipo</div>
              <div class="label-origin">${part.sourceType.description}</div>
            </div>

            <div class="label-part">
              <div class="item-title color-title">Fornecedor</div>
              <div class="item-subtitle">${part.partSupplier.description}</div>
            </div>

            <div class="label-part">
              <div class="item-title color-title">Peça</div>
              <div class="item-subtitle ${part.isNetPriceModified && 'line-through'}">${formatPriceBRL(part.originalPrice)}</div>
            </div>

            <div class="label-part">
              <div class="item-title color-title">Com desconto e majoração</div>
              <div class="${part.isNetPriceModified ? 'item-deprecated line-through' : ''} item-subtitle">${formatPriceBRL(part.individualNetPrice)}</div>
              ${part.isNetPriceModified ? `<div class="item-subtitle new-value">${formatPriceBRL(part.individualNetPrice)}</div>` : ''}
            </div>

            <div class="label-part">
              <div class="item-title color-title">Qtd.</div>
              <div class="item-subtitle">${part.quantity}</div>
            </div>

            <div class="label-part">
              <div class="item-title color-title">Valor Líquido</div>
              <div class="item-subtitle row gap-2">
                ${formatPriceBRL(part.quantity * (part.isNetPriceModified ? part.inputNetPrice : part.individualNetPrice))}
                ${showArrowIndicator(part.originalPrice, part.isNetPriceModified ? part.inputNetPrice : part.individualNetPrice)}
              </div>
            </div>
          </div>
        </div>
        `
      )
      .join('')}
  </div>
  `;
};

const renderBudgetOutsourcedServices = (outsourcedServices: IBudgetItemOutsourcedService[]) => {
  return `
    <div class="parts-wrapper">
      <div class="parts-table">
        ${outsourcedServices
          .map(
            (service) => `
            <div class="flex space-between align-center parts-table-row">
                <div class="label-container">
                  <strong>${capitalizeFirstLetter(service.name)}</strong>
                </div>
                <div class="label-part padding-h-20">
                  <div class="item-title">Valor Total</div>
                  <div class="item-subtitle">${formatPriceBRL(service.price)}</div>
                </div>
            </div>
          `
          )
          .join('')}
      </div>
    </div>
  `;
};

const importStyle = () => {
  return `
  @media print {
    @page {
      margin: 10px;
    }

    .page-break {
      page-break-before: always;
    }

    .page-break-after {
      page-break-after: always;
    }

    .page-break-inside {
      page-break-inside: avoid;
    }
  }

  body {
    margin: auto;
    font-family: 'Montserrat', sans-serif;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    box-sizing: unset;
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
    color: #282828;
    margin: 0;
  }

  #template {
    background-color: #fff;
    min-width: 793px;
    margin: auto;
    padding: 0 20px 20px 20px;
  }

  #header-section {
    padding: 0px;
    color: #282828;
  }

  #info-wrapper {
    display: flex;
    width: 100%;

    .version-value {
      text-wrap: wrap;
      width: 120px;
    }
  }

  #workshop-section {
    padding: 20px 30px 30px 30px;
  }

  .padding-30 {
    padding: 20px 30px 30px 30px;
  }

  .padding-20 {
    padding: 20px;
  }

  .padding-0 {
    padding: 0px !important;
  }

  .padding-v-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .padding-h-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .padding-h-17 {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }

  .padding-h-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .padding-h-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .padding-v-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .padding-v-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .padding-h-32 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  #budget-section {
    padding: 6px 25px;
    background: rgba(0, 198, 198, 0.7);
  }

  #parts-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;

    h1 {
      font-size: 14px;
    }
  }

  #conclusion-section {
    padding-bottom: 20px;
    font-size: 14px;

    h1 {
      font-size: 14px;
    }
  }

  #total-price-wrapper {
    display: flex;
    gap: 15px;
    width: 100%;
  }

  .row-with-logo {
    display: flex;
    flex-direction: row;
  }

  .row-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 6px 17px;
    width: 100%;
    box-sizing: border-box;

    h1 {
      font-size: 14px;
    }

    #info-wrapper {
      font-size: 10px;
    }
  }

  .divider-bottom {
    border-bottom: 1px solid #00000017;
  }

  .divider-right {
    border-right: 1px solid #00000017;
  }

  .divider-left {
    border-left: 1px solid #00000017;
  }

  .divider-top {
    border-top: 1px solid #00000017;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .flex {
    display: flex;
  }

  .logo {
    border-right: 1px solid #00000017;
    display: flex;
    width: 250px;
  }

  .logo > img {
    width: 50px;
    border-radius: 50%;
    margin: auto;
  }

  .label {
    font-weight: 700;
    line-height: 7px;
    letter-spacing: 0em;
  }

  .value {
    font-weight: 500;
    letter-spacing: 0em;
  }

  .form-wrapper {
    margin: 50px 140px;
  }

  .gap-2 {
    gap: 2px;
  }

  .gap-5 {
    gap: 5px;
  }

  .gap-3 {
    gap: 3px;
  }

  .gap-6 {
    gap: 6px;
  }

  .grid-0 {
    display: grid;
    grid-template-columns: 12rem 10rem 10rem;
  }

  .grid-1 {
    display: grid;
    grid-template-columns: 12rem 25rem 0rem;
  }

  .gap-10 {
    gap: 10px;
  }

  .gap-15 {
    gap: 15px;
  }

  .gap-20 {
    gap: 20px;
  }

  .gap-30 {
    gap: 30px;
  }

  .gap-40 {
    gap: 40px;
  }

  .w-100 {
    width: 100%;
  }

  .w-50 {
    width: 50%;
  }

  .w-60 {
    width: 60%;
  }

  .w-65 {
    width: 65%;
  }

  .flex-column {
    flex-direction: column;
  }

  .space-between {
    justify-content: space-between;
  }

  .space-around {
    justify-content: space-around;
  }

  .justify-center {
    justify-content: center;
  }

  .space-evenly {
    justify-content: space-evenly;
  }

  .align-end {
    align-items: flex-end;
  }

  .ft-size-10 {
    font-size: 10px;
  }

  .ft-size-12 {
    font-size: 12px;
  }

  .ft-size-14 {
    font-size: 14px;
  }

  .ft-size-16 {
    font-size: 16px !important;
  }

  .line-through {
    text-decoration: line-through;
  }

  .f-right {
    float: right;
  }

  .f-left {
    float: left;
  }

  .m-left-auto {
    margin-left: auto;
  }

  .m-top-1 {
    margin-top: 1px;
  }

  .m-right-auto {
    margin-right: auto;
  }

  .m-vertical-auto {
    margin: auto 0;
  }

  .m-vertical-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .m-horizontal-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .m-horizontal-auto {
    margin: 0 auto;
  }

  .fw-wrap {
    flex-wrap: wrap;
  }

  .info-divider {
    width: 100%;
    height: 1px;
    background: #ececec;
  }

  .vertical-divider {
    border-left: 1px solid #ececec;
    height: 120%;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #c0c0c0;
  }

  .label-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-wrap: wrap;
    padding: 0 10px;

    > strong {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      color: #000;
    }

    > span {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      color: #6a758b;
    }

    @media (max-width: 1440px) {
      strong {
        font-size: 12px;
      }
    }
  }

  .parts-wrapper {
    padding: 0px;

    h1 {
      font-size: 14px;
    }
  }

  .parts-table {
    padding: 10px 15px;
  }

  .parts-table-row {
    min-height: 60px;
  }

  .parts-table-row:nth-child(odd) {
    background: #f9f9f9;
    border-radius: 4px;
  }

  .service-icons {
    display: flex;
    align-items: center;
    width: 12%;
    gap: 5px;
  }

  .parts-table-name {
    display: grid;
    grid-template-columns: 0.5fr 2.2fr 0.8fr 1fr 1fr 2fr 0.5fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    width: 100%;
    padding: 6px 10px;
  }

  .parts-total-value {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .labor-prices-label {
    color: #6a758b;
    font-family: Montserrat;
    font-weight: 600;
  }

  .labor-prices-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    color: #000;
    font-family: Montserrat;
    font-weight: 700;
  }

  .light {
    font-weight: 300;
  }

  .normal {
    font-weight: 400;
  }

  .medium {
    font-weight: 500;
  }

  .semi-bold {
    font-weight: 600;
  }

  .bold {
    font-weight: 700;
  }

  .parts-table-name > .value {
    color: #7d7d7d;
    font-weight: 700;
  }

  .black {
    color: #000 !important;
  }

  .red {
    color: #b60000 !important;
  }

  .blue {
    color: #0e86ca !important;
  }

  .green {
    color: #08844B !important;
  }

  .align-center {
    align-items: center;
  }

  .conclusion-title {
    margin-bottom: 14px;
  }

  .motive {
    margin-right: 10px;
  }

  .comment-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 20px;
  }

  .widthLarger {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    height: 100%;
    width: 20%;
    gap: 4px;
  }

  .label-part {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    height: 100%;
    gap: 4px;
  }

  .widthSmaller {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    height: 100%;
    width: 7%;
    gap: 4px;
  }

  .widthQuantity {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    height: 100%;
    width: 4%;
    gap: 4px;
  }

  .item-title {
    color: #6a758b;
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 600;

    @media (max-width: 1366px) {
      font-size: 10px;
    }
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .item-subtitle {
    color: #000;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;

    @media (max-width: 1366px) {
      font-size: 10px;
    }
  }

  .item-deprecated {
    font-size: 10px;

    @media (max-width: 1366px) {
      font-size: 8px;
    }
  }

  .new-value {
    color: rgb(14, 134, 202);
  }

  .break-spaces {
    white-space: break-spaces !important;
  }

  #empty-parts {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    padding: 10px;
    color: grey;
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 4px;
    background: #000;
    padding: 10px 25px;
  }

  .section-title {
    color: #fff;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 500;
  }

  .section-date {
    color: #fff;
    text-align: right;
    font-family: 'Roboto';
    font-size: 12px;
    margin: auto 0;
    font-weight: 500;
  }

  .parts-value-wrapper {
    display: flex;
    margin: 0 auto;
  }

  .inspection-code {
    background: #f4f4f4;
    padding: 4px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .parts-value-left {
    width: 70%;
  }

  .budget-resume {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .budget-resume-top {
    display: flex;
    gap: 20px;
  }

  .service-badge {
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
  }

  .value-badge {
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
    border: 1px solid #434343;
  }

  .badge-small {
    padding: 0px 3px;
    border-radius: 10px;
    font-size: 10px;
  }

  .badge-medium {
    padding: 2px 7px;
    border-radius: 12px;
    font-size: 11px;
  }

  .bg-orange {
    background: #f67228;
  }

  .bg-purple {
    background: #3821ae;
  }

  .bg-blue {
    background: #0E86CA;
  }

  .bg-red {
    background: #b60000;
  }

  .bg-green {
    background: #099857;
  }

  .color-title {
    color: #6a758b;
  }

  .color-blue {
    color: #0e7bc4 !important;
  }

  .color-green {
    color: #099857 !important;
  }

  .color-red {
    color: #b60000 !important;
  }

  .label-origin {
    border: 0.6px solid #000000;
    padding: 0.5px 2px;
    border-radius: 2px;
    color: #000;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 600;
  }

  .label-green {
    border: 0.6px solid #099857;
    padding: 0.5px 2px;
    border-radius: 2px;
    color: #099857;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 600;
  }

  .label-genuine {
    border: 0.6px solid #0e86ca;
    padding: 0.5px 2px;
    border-radius: 2px;
    color: #0e86ca;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 600;
  }

  .label-other-sources {
    border: 0.6px solid #6a758b;
    padding: 0.5px 2px;
    border-radius: 2px;
    color: #6a758b;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 600;
  }

  .section {
    border-radius: 4px;
    background: #000;
  }

  .color-white {
    color: #fff;
  }

  .budget-values-services {
    padding: 12px 0px;

    .budget-container {
      width: 49%;
      height: 345px;
    }

    .service-prices-header {
      display: flex;
      padding: 20px;
      flex-wrap: wrap;

      .row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .label {
          color: #656565;
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          white-space: nowrap;
        }

        .value {
          color: #000000;
          font-family: 'Roboto';
          font-weight: 700;
          font-size: 12px;
        }
      }
    }

    .service-prices-body {
      padding: 20px;
      border-top: 1.5px solid #e8ebf1;

      .label {
        color: #656565;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 12px;
        white-space: nowrap;
      }

      .value {
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 18px;
      }

      .row {
        padding: 6px 4px;
      }

      .row:nth-child(even) {
        background: #ebebeb;
        border-radius: 4px;
      }
    }

    .section-top {
      height: inherit;
      padding: 10.7px;
      align-items: center;
      justify-content: space-evenly;
      overflow: hidden;
    }

    .section-middle {
      height: inherit;
      padding: 10.7px;
      align-items: center;
      border-bottom: 1.5px solid #e8ebf1;
      border-top: 1.5px solid #e8ebf1;
      justify-content: space-evenly;
    }

    .section-bottom {
      height: inherit;
      padding: 10.7px;
      align-items: center;
      background: #e8ebf1;
      justify-content: space-evenly;
    }

    .section-label {
      color: #656565;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 11px;
      white-space: nowrap;
    }

    .section-value {
      color: #000000;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 14px;
      font-weight: 600;
    }

    .orange-dot {
      width: 10px;
      height: 10px;
      background: #f67228;
      border-radius: 50%;
    }

    .red-dot {
      width: 10px;
      height: 10px;
      background: #c60c00;
      border-radius: 50%;
    }

    .purple-dot {
      width: 10px;
      height: 10px;
      background: #3821ae;
      border-radius: 50%;
    }

    .black-dot {
      width: 10px;
      height: 10px;
      background: #232323;
      border-radius: 50%;
    }
  }

  .subtitle {
    .description {
      font-size: 12px;
    }
  }

  .part-type {
    display: flex;
    gap: 20px;
    padding: 12px 20px;
    margin-bottom: 20px;
  }

  .border-container {
    border: 1.5px solid #e8ebf1;
    border-radius: 4px;
  }

  .conclusion-list {
    padding: 15px 0px;
  }

  .conclusion-card {
    display: flex;
    flex-direction: column;
    border: 1.5px solid #e8ebf1;
    border-radius: 8px;
    font-family: 'Roboto';

    .header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background: #e8ebf1;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  .conclusion-row {
    padding: 20px;
    border-top: 1px solid #e8ebf1;
    border-bottom: 1px solid #e8ebf1;
  }

  .part-changes-label {
    font-size: 10px;
    color: white;
    margin: auto 0;
    padding: 3px 4px;
    border-radius: 4px;
    font-weight: 500;
  }
  `;
};

const ReportService: IReportService = {
  renderReportPreview: (budget, inspection, clientLogo) => {
    return `<!DOCTYPE html>
    <html>
      <head>
        <title>Relatório do Orçamento</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" media="all">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin media="all">
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap" rel="stylesheet" media="all">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
      </head>
      <style>
        ${importStyle()}
      </style>
      <body>
        <div id="template">
          <div id="header-section" class="flex flex-column">
            <div class="flex">
              <div class="flex flex-column w-100">
                <div class="row-with-logo">
                  <div class="logo">
                    ${clientLogo ? `<img src="${clientLogo}" alt="Logo" width="110"/>` : ''}
                  </div>

                  <div class="row-wrapper divider-bottom gap-6 divider-right">
                    <div class="flex gap-10">
                      <img src="${vehicleIcon}" alt="clock" width="18" />
                      <h1>Dados do veículo</h1>
                    </div>
                    <div id="info-wrapper" class="space-between flex-column gap-10">
                      <div class="flex gap-30">
                        <div class="flex flex-column gap-3">
                          <div class="label">Marca</div>
                          <div class="value">${inspection.product?.vehicle?.brand || '-'}</div>
                        </div>

                        <div class="flex flex-column gap-3">
                          <div class="label">Placa</div>
                          <div class="value">${inspection.product?.plate || '-'}</div>
                        </div>

                        <div class="flex flex-column gap-3">
                          <div class="label">Modelo</div>
                          <div class="value">${inspection.product?.vehicle?.model || '-'}</div>
                        </div>
                      </div>

                      <div class="flex gap-30">
                        <div class="flex flex-column gap-3">
                          <div class="label">Versão</div>
                          <div class="value version-value">${inspection.product?.vehicle?.version || '-'}</div>
                        </div>

                        <div class="flex flex-column gap-3">
                          <div class="label">Ano</div>
                          <div class="value">${inspection.product?.vehicle?.modelYear || '-'}</div>
                        </div>

                        <div class="flex flex-column gap-3">
                          <div class="label">Odômetro</div>
                          <div class="value">${getVehicleKilometers(inspection.product?.vehicle?.kilometers)}</div>
                        </div>

                        <div class="flex flex-column gap-3">
                          <div class="label">Chassi</div>
                          <div class="value">${inspection.product?.vehicle?.chassis || '-'}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row-wrapper divider-bottom gap-6 padding-h-0">
                    <div class="ft-size-12 row-wrapper padding-h-17 gap-6 row align-center divider-bottom">
                      <div class="label">Sinistro:</div>
                      <div class="value">${inspection.code || '-'}</div>
                    </div>

                    <div class="row-wrapper gap-6 padding-h-17">
                      <div class="flex gap-10">
                        <img src="${customerIcon}" alt="clock" width="18" />
                        <h1>Dados do segurado</h1>
                      </div>
                      <div id="info-wrapper" class="gap-20">
                        <div class="flex flex-column gap-3">
                          <div class="label">Nome do Segurado</div>
                          <div class="value">${inspection.customer?.name || '-'}</div>
                        </div>

                        <div class="flex flex-column gap-3">
                          <div class="label">Telefone</div>
                          <div class="value">${inspection.customer?.phone || '-'}</div>
                        </div>

                        <div class="flex flex-column gap-3">
                          <div class="label">Franquia</div>
                          <div class="value">${formatPriceBRL(budget.deductible)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div class="section-title">Orçamento ${budget.version}</div>
            <div class="section-date"><span class="semi-bold">Data de criação:</span> ${DateService.formatDate(
              budget.createdAt
            )}</div>
          </section>

          <div class="row-wrapper gap-15 padding-v-15 padding-h-25">
            <div id="info-wrapper" class="space-between fw-wrap gap-10">
              <div class="row gap-30">
                <div class="flex gap-10">
                  <img src="${workshopIcon}" alt="clock" width="18" />
                  <h1>Dados da oficina</h1>
                </div>

                <div class="flex flex-column gap-3">
                  <div class="label">Nome</div>
                  <div class="value">${budget.workshop?.name || '-'}</div>
                </div>

                <div class="flex flex-column gap-3">
                  <div class="label">CNPJ</div>
                  <div class="value">${budget.workshop?.cnpj || '-'}</div>
                </div>

                <div class="flex flex-column gap-3">
                  <div class="label">Telefone</div>
                  <div class="value">${budget.workshop?.phone || '-'}</div>
                </div>

                <div class="flex flex-column gap-3">
                  <div class="label">Email</div>
                  <div class="value">${budget.workshop?.email || '-'}</div>
                </div>
              </div>
            </div>

            <div id="info-wrapper" class="space-between fw-wrap gap-10">
              <div class="row gap-30">
                <div class="flex flex-column gap-3">
                  <div class="label">Endereço</div>
                  <div class="value">${budget.workshop?.address ? formatAddress(budget.workshop?.address) : '-'}</div>
                </div>
              </div>
            </div>
          </div>

          <div id="parts-section">
              ${showPartsAndServicesIfExist(budget, inspection)}
          </div>
          <div class="page-break-inside">
            <div id="conclusion-section">
              <div class="flex gap-10 divider-bottom divider-top padding-v-10 padding-h-20 section">
                <img src="${conclusionWhiteIcon}" alt="clock" width="18" />
                <h1 class="bold color-white">Conclusão</h1>
              </div>

              <div class="conclusion-list flex column gap-5">
                ${budget.conclusions
                  .filter((conclusion) => !conclusion.isArchived)
                  .map(
                    (conclusion) => `
                    <div class="conclusion-card border-container">
                      <div class="header">
                        <div class="flex align-center gap-5">
                          <div class="semi-bold">Responsável:</div>
                          <span class="semi-bold">${conclusion.user.name}</span>
                        </div>

                        <div class="flex align-center">
                          <div class="">${DateService.formatDateAndTime(conclusion.createdAt)}</div>
                        </div>
                      </div>

                      <div class="flex gap-30 conclusion-row">
                        <div class="flex align-center">
                          <div class="label motive">Tipo de conclusão:</div>
                          <span>${conclusion.type}</span>
                        </div>

                        <div class="flex align-center">
                          <div class="label motive">Motivo:</div>
                          <span>${conclusion.reason}</span>
                        </div>
                      </div>

                      <div class="comment-wrapper">
                        <div class="title semi-bold">Comentário de conclusão</div>

                        <div class="comment light">${conclusion.comment}</div>
                      </div>
                    </div>
                  `
                  )
                  .join('')}
              </div>
            </div>
          </div>


        </div>
      </body>
    </html>`;
  },
};

export default ReportService;
