import React from 'react';
import AbleLogo from 'assets/svg/able-extended-logo.svg';
import DateService from 'services/DateService/dateService';
import CalendarIcon from 'assets/svg/invoices-icons/calendar.svg';
import CompanyIcon from 'assets/svg/invoices-icons/building.svg';
import PriceIcon from 'assets/svg/invoices-icons/price.svg';
import ListIcon from 'assets/svg/invoices-icons/product-list.svg';
import { priceFormatter } from 'utils/formatPrice';
import { IInvoiceWithExpenses } from 'commom/types/Invoices.types';
import InvoicesService from 'services/ApiService/InvoicesService/invoicesService';
import {
  Logo,
  Container,
  Header,
  SummaryContainer,
  SummaryItem,
  SummaryInfo,
  SummaryTitle,
  SummaryValue,
  SummaryIcon,
  Section,
  SectionTitle,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from './InvoiceReport.styles';

interface IInvoiceReportData {
  invoice?: IInvoiceWithExpenses;
}

const InvoiceReport = ({ invoice }: IInvoiceReportData) => {
  const filterExpensesByCompany = (companyName: string) => {
    return invoice?.invoiceExpenses?.filter((expense) => expense.companyName === companyName);
  };

  const filterUniqueCompanies = () => {
    const companies = invoice?.invoiceExpenses?.map(({ companyName }) => companyName);
    return companies?.filter((item, index) => companies.indexOf(item) === index);
  };

  const groupExpensesByCompany = () => {
    return filterUniqueCompanies()?.map((companyName) => {
      return {
        companyName,
        expenses: filterExpensesByCompany(companyName),
      };
    });
  };

  return (
    <div style={Container}>
      <header style={Header}>
        <img src={AbleLogo} alt="Logo" style={Logo} />
        <div style={SummaryContainer}>
          <div style={SummaryItem}>
            <div style={SummaryInfo}>
              <div style={SummaryTitle}>Cobrança à</div>
              <b style={SummaryValue}>{invoice?.client.name}</b>
            </div>
            <img src={CompanyIcon} alt="Cliente" style={SummaryIcon} />
          </div>
          <div style={SummaryItem}>
            <div style={SummaryInfo}>
              <div style={SummaryTitle}>Período</div>
              <b
                style={SummaryValue}
              >{`${DateService.formatDateWithoutYear(invoice?.startDate || '')} a ${DateService.formatDateWithoutYear(invoice?.endDate || '')}`}</b>
            </div>
            <img src={CalendarIcon} alt="Calendário" style={SummaryIcon} />
          </div>
          <div style={SummaryItem}>
            <div style={SummaryInfo}>
              <div style={SummaryTitle}>Produtos cobrados</div>
              <b style={SummaryValue}>{invoice?.expensesCount}</b>
            </div>
            <img src={ListIcon} alt="Lista de produtos" style={SummaryIcon} />
          </div>
          <div style={SummaryItem}>
            <div style={SummaryInfo}>
              <div style={SummaryTitle}>Preço</div>
              <b style={SummaryValue}>{priceFormatter.format(invoice?.price || 0)}</b>
            </div>
            <img src={PriceIcon} alt="Preço" style={SummaryIcon} />
          </div>
        </div>
      </header>
      {groupExpensesByCompany()?.map(({ companyName, expenses }, index) => (
        <div key={index} style={Section}>
          <h1 style={SectionTitle}>{companyName}</h1>
          <table style={Table}>
            <thead>
              <tr>
                <th style={TableHeader}>Produto</th>
                <th style={TableHeader}>Atendimento</th>
                <th style={TableHeader}>Operador</th>
                <th style={TableHeader}>Data da operação</th>
                <th style={TableHeader}>Preço</th>
              </tr>
            </thead>
            <tbody>
              {expenses?.map(({ type, inspectionCode, responsibleName, startDate, price }, rowIndex) => (
                <tr key={rowIndex} style={rowIndex % 2 === 0 ? TableRow : {}}>
                  <td style={TableCell}>{InvoicesService.getInvoiceExpenseType(type)}</td>
                  <td style={TableCell}>{inspectionCode || '-'}</td>
                  <td style={TableCell}>{responsibleName || '-'}</td>
                  <td style={TableCell}>{DateService.formatDateAndHourToPTBRWithDot(startDate)}</td>
                  <td style={TableCell}>{priceFormatter.format(price || 0)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default InvoiceReport;
