import { Button } from 'components';
import { useActiveProfile } from 'contexts/activeProfile/useActiveProfile';
import { useAppSelector } from 'hooks/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DateService from 'services/DateService/dateService';
import ReportService from 'services/ReportService/reportService';
import { selectBudget } from 'stores/presentBudget/presentBudget.selector';
import { selectInspection } from 'stores/presentInspection/presentInspection.selector';
import {
  BudgetActions,
  BudgetInfo,
  Container,
  Header,
  PrintStyles,
  ReportContainer,
  SubTitle,
  Title,
} from './Report.styles';

const Report = () => {
  const [reportTemplate, setReportTemplate] = useState<string>('');
  const { getCurrentClient } = useActiveProfile();
  const client = getCurrentClient();
  const inspection = useAppSelector(selectInspection);
  const budget = useAppSelector(selectBudget);

  const handleDataReportPreview = useCallback(async () => {
    try {
      if (client) {
        const template = ReportService.renderReportPreview(budget, inspection, client.logoUrl);

        setReportTemplate(template);
      }
    } catch (error) {
      toast.error('Não foi possível gerar o relatório. Tente novamente mais tarde.');
    }
  }, [budget, inspection, client]);

  const printPDF = () => {
    window.print();
  };

  useEffect(() => {
    handleDataReportPreview();
  }, [handleDataReportPreview]);

  return (
    <Container>
      <ReportContainer>
        <PrintStyles />
        <Header>
          <BudgetInfo>
            <Title>Orçamento</Title>
            <SubTitle>Data de criação: {DateService.formatDateAndTime(budget.createdAt)}</SubTitle>
          </BudgetInfo>

          <BudgetActions>
            <Button text="Imprimir" variant="outlined" onClick={printPDF} />
          </BudgetActions>
        </Header>
        <div id="printable" dangerouslySetInnerHTML={{ __html: reportTemplate }} />
      </ReportContainer>
    </Container>
  );
};

export default Report;
