export const priceFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const removerPriceFormatter = (price: string): string => {
  return price.replace('R$', '').replace('.', '').replace(',', '.').trim();
};

export const removerPercentFormatter = (price: string): number => {
  return parseFloat(price.replace('%', '').replace(',', '.'));
};

export const addPercentFormatterNumber = (price: number): string => {
  return price.toString().replace('.', ',') + '%';
};
